import React, { Fragment, useEffect } from "react";
import ReactGA from 'react-ga';
import { ConfigProvider } from 'antd'
// import Snowfall from 'react-snowfall'
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainPage from "./pages/MainPage";
import Vacancies from "./pages/Vacancies";
import NewMenu from "./pages/NewMenu";
import Checkout from './pages/Checkout';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-LKY88K9LJ5');
  }, [])

  return (
    <Fragment>
      {/* <Snowfall style={{ position: 'fixed', }} /> */}
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              dotActiveBorderColor: '#CF3839',
              dotBorderColor: '#CF3839',
              handleColor: '#CF3839',
              handleActiveColor: '#CF3839',
              handleSize: 13,
              handleLineWidth: 3,
              controlSize: 30,
              trackBgDisabled: '#CF383977',
            },
          },
        }}
      >
        <div className="gradient">
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<MainPage />} />
              <Route exact path="/vacancies" element={<Vacancies />} />
              <Route exact path="/newmenu" element={<NewMenu />} />
              <Route exact path="/checkout" element={<Checkout />} />
              {/* <Route element={null} /> */}
            </Routes>
          </BrowserRouter>
        </div>

      </ConfigProvider>
    </Fragment>
  );
}

export default App;
