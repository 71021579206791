import React, { useState, useEffect, } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'

function Vacancies() {
  const navigate = useNavigate()

  const [list] = useState([
    // { title: 'Касир / Сashier', link: 'https://docs.google.com/forms/d/e/1FAIpQLScjWprC8gpQ6CVE-RHo9rwJPRupZuF2PDsfzDFC3nbnyfNODQ/viewform', },
    // { title: 'Касир / Сashier', link: 'https://docs.google.com/forms/d/e/1FAIpQLSdHVgaEOno--Wv2OJX5goHLYrSzEpDoKP3Z5JwOD8OFHHKVOg/viewform?usp=sf_link', }
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='vacancies-page'>
      <div className='page-head'>
        <div className='page-nav' onClick={() => navigate('/')}>
          <div className='page-icon back' />
          <div className='page-title'>
            Назад
          </div>
        </div>

        <div className='page-icon' />
      </div>
      {list?.length ?
        list?.map((vacancy, index) => (
          <div
            className='vacancy-item'
            onClick={() => window.location = vacancy?.link}
            key={index}
          >
            <div className='vacancy-image'>
              <div className='vacancy-title'>
                {vacancy?.title}
              </div>

              <div className='vacancy-caption'>
                Подати заявку / Аpply
              </div>
            </div>

            {/* <div className='vacancy-button-text'>
            Подати заявку / Аpply
          </div> */}
          </div>
        )) : <div className='no-vacancy'>Немає відкритих вакансій</div>
      }
    </div >
  )
}

export default Vacancies
